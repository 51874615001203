import clip_icon from './clip_icon.svg';
import './App.css';
import {
  getWidgetLink,
  getInitParms
} from './Utils';
import React from 'react';

const currencies = {
  "ARS": "Pesos argentinos",
  "BRL": "Reales brasileños",
  "CLP": "Pesos chilenos",
  "COP": "Pesos colombianos",
  "MXN": "Pesos mexicanos",
  "PEN": "Soles peruanos",
  "USD": "Dolares estadounidenses",
  "UYU": "Pesos uruguayos",
}

class Link extends React.Component {
  copyLink() {
    var copyTextarea = document.getElementById('input-link');
    copyTextarea.focus();
    copyTextarea.select();
    document.execCommand('copy');
  }

  render() {
    return (
      <div id="block-result" className="hidden">
        <label htmlFor="input-link">Link de Pago:</label>
        <div className="block">
          <input
            id="input-link"
            className="result"
            type="text"
            name="input-link"
            value={this.props.link}
          />
          <a className="btn-2" onClick={this.copyLink} >
            <img src={clip_icon} className="bi" alt="clip-icon" />Copiar Link
          </a>
        </div>
      </div>
    );
  }
}

class LinkGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      concept: this.getConcept(),
      currency: this.getCurrency(),
      amount: null,
      link: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
  }

  componentDidMount() {
    this.changeCurrencyLogo();
  }

  changeCurrencyLogo() {
    let currency = this.getCurrency();
    if (currencies[currency]) {
      document.getElementById('input-amount-large').style["background"] = `url("countries/${currency}.svg") no-repeat`;
      document.getElementById('input-amount-small').style["background"] = `url("countries/${currency}.svg") no-repeat`;
      document.getElementById('input-amount-large').setAttribute("placeholder", `El monto es en ${currencies[currency]}`) ;
      document.getElementById('input-amount-small').setAttribute("placeholder", `Monto en ${currency}`) ;
    } else {
      alert("Moneda invalida!");
    }
  }

  getConcept() {
    return new URLSearchParams(window.location.search).get("concept") || "";
  }

  getCurrency() {
    return new URLSearchParams(window.location.search).get("currency") || "UYU";
  }

  getAmount() {
    let amount = document.getElementById('input-amount-large').value || document.getElementById('input-amount-small').value;
    return amount.replace(',', '.');
  }

  cleanAmount() {
    document.getElementById('input-amount-large').value = null;
    document.getElementById('input-amount-small').value = null;
  }

  validateInput() {
    let amount = this.getAmount();
    if (!amount.match(/^[0-9]+(\.{1}[0-9]{1,2}){0,1}$/)) {
      alert("Ingresa el monto solo en numeros con 2 digitos de precisión!");
      return false;
    }
    return true;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateInput()) {
      document.getElementById('block-result').classList = [];
      this.cleanAmount();
      this.setState(state => ({
        link: getWidgetLink(
          window.location.origin,
          this.props.widgetKey,
          state.currency,
          state.concept,
          state.amount
        )
      }));
    }
  }

  handleChangeAmount(e) {
    this.setState({ amount: e.target.value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1') });
  }

  render() {
    return (
      <div id="gen-link-form" className="content">
        <div className="main">
          <div className="left">
            <p>WIDGET DE PAGOS CUENTA A CUENTA</p>
            <h2>Genera tu link de pago</h2>
            <p>Ingresa el monto que necesitás que te tranfieran y luego envía el link generado</p>
            <div className="block">
              <input
                  type="text"
                  inputMode="decimal"
                  id="input-amount-large"
                  className="logo-country"
                  placeholder="El monto es en pesos uruguayos"
                  name="amount"
                  value={this.state.amount}
                  onInput={this.handleChangeAmount}
              />
              <input
                  type="text"
                  inputMode="decimal"
                  id="input-amount-small"
                  className="logo-country"
                  placeholder="Monto en UYU"
                  name="amount"
                  value={this.state.amount}
                  onInput={this.handleChangeAmount}
              />
              <a className="btn" onClick={this.handleSubmit}> Generar Link &gt;</a>
            </div>
            <Link link={this.state.link} />
          </div>
          <div className="right">
            <img src="imgs/fire.png" alt="Fuego - " title="Pagos cuenta a cuenta"/>
          </div>
        </div>
      </div>
    );
  }
}

class EmbedWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetOk: null,
      params: getInitParms(window.location.hash.substring(1))
    };
    this.openWidget = this.openWidget.bind(this);
    this.openWidgetIfValid = this.openWidgetIfValid.bind(this);

  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_WIDGET_URL;
    script.async = true;
    script.onload = () => this.scriptLoaded();
    document.body.appendChild(script);
  }

  scriptLoaded() {
    this.openWidgetIfValid();
  }

  openWidgetIfValid() {
    if (this.state.params) {
      this.openWidget();
    } else {
      this.setState(state => ({ widgetOk: false }));
    }
  }

  getInitParms() {
    return getInitParms(window.location.hash.substring(1));
  }

  openWidget() {
    let params = this.state.params;
    window.Prometeo.init(params.widgetKey).open({
      allowedFeatureLevel: 2,
      currency: params.currency,
      amount: params.amount,
      concept: params.concept
    });
    this.setState(state => ({ widgetOk: true }));
  }

  render() {
    let content;
    if (this.state.widgetOk == null) {
      content = <div className="main-2">
        <h1 style={{paddingTop: "4%"}}>Cargando...</h1>
      </div>
    } else if (!this.state.widgetOk) {
      content = <div id="widget-error-banner" className="main-2">
        <img src="imgs/error2.png" alt="Error - " title="Link not found"/>
        <h1 style={{paddingTop: "4%"}}>😅 ¡Ups! Link de pago inválido</h1>
        <p>Este link que te han proporcionado no funciona.</p>
      </div>;
    } else {
      content = <div id="widget-ok-banner" className="main">
      <div className="left-sec">
        <div>
          <h2>Prueba nuestros pagos A2A</h2>
          <p>Expande tu negocio por toda Latinoamérica, recibe los pagos sin intermediarios y con la inmediatez que necesitas.</p>
          <div>
            <br/><br/>
            <a className="btn" onClick={this.openWidgetIfValid} >Realizar un pago</a>
          </div>
        </div>
      </div>
      <div className="right-sec">
        <img src="imgs/Pagos.png" alt="Pagos cuenta a cuenta" title="Pagos cuenta a cuenta"/>
      </div>
    </div>;
    }

    return (
      <div id="open-widget" className="content">
        {content}
      </div>);
  }
}

class Menu extends React.Component {
  render() {
    return (
      <div className="Menu">
        <div>
          <h1><img src="imgs/Prometeo-blanco.png" alt="Prometeo" title="Prometeo" width="115px" /></h1>
        </div>
      </div>
    )
  }
}

class Footer extends React.Component {
  render() {
    return (
      <footer>
          <p style={{color: "#677782", fontSize: "14px"}}>Con ❤️ por <a style={{color: "#677782", textDecoration: "none"}} href="https://prometeoapi.com/"> Prometeo</a>.</p>
      </footer>
    )
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetKey: this.getWidgetKey(),
    };
  }

  getWidgetKey() {
    return new URLSearchParams(window.location.search).get("widgetKey");
  }

  render() {
    return (
      <div>
        <Menu />
        {this.state.widgetKey ? <LinkGenerator widgetKey={this.state.widgetKey}/>: <EmbedWidget/>}
        <Footer />
      </div>
    );
  }
}

export default App;
