const codePoints = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
//This can be any string of permitted characters

const numberOfValidInputCharacters = () => {
    return codePoints.length;
}

const codePointFromCharacter = (character) => {
    return codePoints.indexOf(character);
}

const characterFromCodePoint = (codePoint) => {
    return codePoints.charAt(codePoint);
}

export const generateCheckCharacter = (input) => {
    let factor = 2;
    let sum = 0;
    let n = numberOfValidInputCharacters();

    // Starting from the right and working leftwards is easier since
    // the initial "factor" will always be "2".
    for (let i = input.length - 1; i >= 0; i--) {
        let codePoint = codePointFromCharacter(input.charAt(i));
        let addend = factor * codePoint;

        // Alternate the "factor" that each "codePoint" is multiplied by
        factor = (factor == 2) ? 1 : 2;

        // Sum the digits of the "addend" as expressed in base "n"
        addend = (Math.floor(addend / n)) + (addend % n);
        sum += addend;
    }

    // Calculate the number that must be added to the "sum"
    // to make it divisible by "n".
    let remainder = sum % n;
    let checkCodePoint = (n - remainder) % n;
    return characterFromCodePoint(checkCodePoint);
}

export const validateCheckCharacter = (input) => {
    let factor = 1;
    let sum = 0;
    let n = numberOfValidInputCharacters();

    // Starting from the right, work leftwards
    // Now, the initial "factor" will always be "1"
    // since the last character is the check character.
    for (let i = input.length - 1; i >= 0; i--) {
        let codePoint = codePointFromCharacter(input.charAt(i));
        let addend = factor * codePoint;

        // Alternate the "factor" that each "codePoint" is multiplied by
        factor = (factor == 2) ? 1 : 2;

        // Sum the digits of the "addend" as expressed in base "n"
        addend = (Math.floor(addend / n)) + (addend % n);
        sum += addend;
    }
    let remainder = sum % n;
    return (remainder == 0);
}

export const encodeWidgetID = (hexId) => {
  hexId = hexId.replace(/-/g, '');
  let h1 = hexId.substring(0,10);
  let h2 = hexId.substring(10,20);
  let h3 = hexId.substring(20);
  let rh1 = parseInt(h1.replace(/-/g, ''), 16).toString(36);
  let rh2 = parseInt(h2.replace(/-/g, ''), 16).toString(36);
  let rh3 = parseInt(h3.replace(/-/g, ''), 16).toString(36);
  return `${rh1}.${rh3}.${rh2}`;
}

export const decodeWidgetID = (base36Id) => {
  let h = base36Id.split(".");
  let h1 = parseInt(h[0], 36).toString(16).padStart(10, '0');
  let h2 = parseInt(h[2], 36).toString(16).padStart(10, '0');
  let h3 = parseInt(h[1], 36).toString(16).padStart(12, '0');
  return `${h1}${h2}${h3}`;
}

export const encodeCurrency = (currencyCode) => {
  return parseInt(currencyCode, 36);
}

export const decodeCurrency = (base36currency) => {
  return parseInt(base36currency).toString(36).toUpperCase();
}

export const encodeBase64 = (value) => {
  return btoa(value);
}

export const decodeBase64 = (base64Value) => {
  return atob(base64Value);
}

export const mixArray = (array) => {
  let a = [];
  if (array.length === 1) return array;
  for (let i = 0, j = array.length-1; i < array.length && j > 0; i++, j--){
    if (j < i) {
      break;
    }
    if (j == i) {
      a.push(array[i]);
      break;
    }
    a.push(array[i]);
    a.push(array[j]);
  }
  return a;
}

export const unmixArray = (array) => {
  let a = [];
  let b = [];
  if (array.length == 1) return array;
  for (let i = 0, j = array.length; i <= array.length && j > 0; i++, j--){
    if (i % 2 == 0 && array[i] != null) {
      a.push(array[i]);
    }
    if (j % 2 != 0 && array[j] != null) {
      b.push(array[j]);
    }
  }
  return a.concat(b);
}

export const encodeParamHash  = (widgetKey, currency, concept, amount) => {
  let encodedWidgetKey = encodeWidgetID(widgetKey);
  let encodedCurrency = encodeCurrency(currency);
  let encodedConcept = encodeBase64(concept);
  let encodedAmount = encodeBase64(amount);
  let key = `${encodedWidgetKey}-${encodedCurrency}-${encodedAmount}-${encodedConcept}-`;
  return `${key}${generateCheckCharacter(key)}`;
}

export const decodeParamHash  = (paramsHash) => {
  if (paramsHash == "" || !validateCheckCharacter(paramsHash)) {
    return null
  }
  let params = paramsHash.split("-");
  params.pop(); // removes check digit
  return {
    widgetKey: decodeWidgetID(params[0]),
    currency: decodeCurrency(params[1]),
    amount: decodeBase64(params[2]),
    concept: decodeBase64(params[3] ?? "")
  }
}

export const scrambleID = (id, inputSeed = null) => {
  let array = id.split("");
  let seed = inputSeed === null ? Math.floor(Math.random() * array.length) : inputSeed;
  let a = mixArray(array.slice(0, seed));
  let b = mixArray(array.slice(seed));
  let scrambledID = a.concat(b);
  let hexSeed = seed.toString(16).padStart(2, '0');
  return `${hexSeed}${scrambledID.join("")}`
}

export const descrambleID = (fullScrambledID) => {
  let hexSeed = fullScrambledID.substring(0,2);
  let seed = parseInt(hexSeed, 16);
  let scrambledID = fullScrambledID.substring(2).split("");
  let a = unmixArray(scrambledID.slice(0, seed));
  let b = unmixArray(scrambledID.slice(seed));
  let descrambledID = a.concat(b);
  return descrambledID.join("");
}

export const getInitParms  = (scrambledParamsHash) => {
  let paramsHash = descrambleID(scrambledParamsHash);
  return decodeParamHash(paramsHash);
}

export const getWidgetLink = (widgetUrl, widgetKey, currency, concept, amount) => {
  let key = encodeParamHash(widgetKey, currency, concept, amount)
  let obsKey = scrambleID(key);
  return `${widgetUrl}/#${obsKey}`;
}